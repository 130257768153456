'use strict';

angular.module('windmanagerApp')
  .directive('componentMap', function() {
    return {
      templateUrl: 'components/directives/finding/componentMap/componentMap.html',
      restrict: 'EA',
      scope: {
        components: '=',
        turbine: '=',
        disableNavigation: "="
        // repairs: '='
      },
      controller: 'ComponentMapCtrl',
      controllerAs: 'compMap',
      link: function(scope, element, attrs) {
      }
    };
  });
